import React from "react";
import "./../index.scss";
import "./artist.scss";
import { Header, Footer, Contact } from "./../components";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image";

type Artist = {
  name: string;
  title: string;
  info: {
    [s: string]: string;
    Height: string;
  };
  description: string;
  imageName: string;
};

const artists: Artist[] = [
  // {
  //   name: "Alice Goodman",
  //   title: 'Dancer',
  //   info: {
  //     Ethnicity: "White",
  //     Height: "5'5",
  //     Hair: "Blonde",
  //     Eyes: "Blue",
  //   },
  //   description: "",
  //   image: "artist1.png",
  // },
  {
    name: "Jac Cox",
    title: "Dancer",
    info: {
      Ethnicity: "White",
      Hair: "Brown",
      Eyes: "Brown",
      Height: "5'5",
    },
    description: "hello",
    imageName: "artist2",
  },
  // {
  //   name: "Rob Wilson",
  //   title: 'Dancer',
  //   info: {
  //     Ethnicity: "White",
  //     Hair: "Brown/short",
  //     Eyes: "Brown",
  //   },
  //   description: "",
  //   image: "artist4.png",
  // },
  {
    name: "Cloe Hastings",
    title: "Dancer",
    info: {
      Ethnicity: "White",
      Height: "5'5",
      Hair: "Blonde",
      Eyes: "Blue",
      ["Dress size"]: "8",
    },
    description: "",
    imageName: "artist5",
  },
  {
    name: "Chilly Karabeyaz",
    title: "Belly Dancer & Singer",
    info: {
      Ethnicity: "Turkish Cypriot",
      Height: "5'2",
      Hair: "Brown",
      Eyes: "Brown",
      ["Dress size"]: "6",
    },
    description: "",
    imageName: "artist6",
  },
  {
    name: "Reece Darlington Delaire",
    info: {
      Height: "5'11",
      Hair: "Black",
      Ethnicity: "Black Caribbean",
      Eyes: "Dark brown",
    },
    title: "Dancer",
    imageName: "artist13",
    description: "",
  },
  {
    name: "Hannah Nazareth",
    title: "Dancer",
    info: {
      Ethnicity: "Indian/Portugese",
      Hair: "Brown",
      Eyes: "Brown",
      Height: "5'7",
      ["Dress size"]: "8",
    },
    description: "",
    imageName: "artist8",
  },
  {
    name: "Hayley May Larkin",
    title: "Dancer & Fire",
    info: {
      Ethnicity: "White",
      ["Dress size"]: "6",
      Eyes: "Blue",
      Hair: "Blonde",
      Height: "5'5",
    },
    description: "",
    imageName: "artist9",
  },
  {
    name: "Cameron Antony",
    info: {
      Height: "5'11",
      Hair: "Black",
      Ethnicity: "Mixed Race",
      Eyes: "Green",
    },
    title: "Dancer",
    imageName: "artist14",
    description: "",
  },
  {
    name: "Liam Raven",
    title: "Dancer",
    info: {
      Ethnicity: "White",
      Height: "5'9",
      Hair: "Brown",
      Eyes: "Blue",
    },
    description: "",
    imageName: "artist10",
  },
  {
    name: "Jenifer Louise",
    title: "Dancer",
    info: {
      Ethnicity: "White",
      Height: "5'2",
      Hair: "Blonde",
      Eyes: "Brown",
      ["Dress size"]: "6",
    },
    description: "",
    imageName: "artist11",
  },
  {
    name: "Melissa McAllister",
    title: "Dancer",
    info: {
      Ethnicity: "White",
      Height: "5'6",
      Hair: "Brown",
      Eyes: "Green",
      ["Dress size"]: "8",
    },
    description: "",
    imageName: "artist12",
  },
  {
    name: "Charlotte Ridd",
    title: "Dancer",
    info: {
      Ethnicity: "white",
      Height: "5’6",
      Hair: "blonde",
      Eyes: "blue",
      ["Dress-size"]: "6",
    },
    description: "",
    imageName: "artist17",
  },
  {
    name: "Georgia Webb",
    title: "Dancer",
    info: {
      Ethnicity: "White",
      Height: "5’5",
      Hair: "blonde",
      Eyes: "blue",
      ["Dress-size"]: "6",
    },
    description: "",
    imageName: "artist16",
  },
  {
    name: "Sheerice Bisram",
    title: "Dancer",
    info: {
      Ethnicity: "South American",
      Height: "5’4",
      Hair: "brown",
      Eyes: "dark brown",
      ["Dress-size"]: "8/10",
    },
    description: "",
    imageName: "artist18",
  },
];

export default ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Showelli | Artists</title>
        <meta
          name="description"
          content="Showelli's professional artists all listed in one place. Available now for your events, parties or venue."
        />
        <meta
          name="keywords"
          content="Showelli, dancers, performers, choreography, music videos, concerts, backup dancers, fire acts"
        />
      </Helmet>
      <Header />
      <div className="hero is-primary-50">
        <div className="hero-body">
          <div className="container">
            <h2 className="title">Our Artists</h2>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline artist-gallery is-centered">
            {artists
              .sort((a, b) => {
                console.log(a, b);
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
              })
              .map(artist => (
                <div
                  className="artist-container column is-one-quarter"
                  key={artist.name}
                >
                  <Img
                    fluid={data[artist.imageName].childImageSharp.fluid}
                    alt={`${artist.name} - Showelli Dancer`}
                  />
                  <div className="artist-description">
                    <div className="content">
                      <h5 className="title">{artist.name}</h5>
                      <h6 className="subtitle">{artist.title}</h6>
                      <ul>
                        {Object.keys(artist.info).map(key => (
                          <li
                            key={
                              artist.info[key] + "-" + key + "-" + artist.name
                            }
                          >
                            <b>{key}</b> - {artist.info[key]}
                          </li>
                        ))}
                      </ul>
                      {/* <p>{artist.description}</p> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Footer />
      <Contact />
    </>
  );
};

export const query = graphql`
  query {
    artist1: file(relativePath: { eq: "artist1.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist2: file(relativePath: { eq: "artist2.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist3: file(relativePath: { eq: "artist3.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist4: file(relativePath: { eq: "artist4.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist5: file(relativePath: { eq: "artist5.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist6: file(relativePath: { eq: "artist6.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist7: file(relativePath: { eq: "artist7.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist8: file(relativePath: { eq: "artist8.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist9: file(relativePath: { eq: "artist9.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist10: file(relativePath: { eq: "artist10.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist11: file(relativePath: { eq: "artist11.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist12: file(relativePath: { eq: "artist12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist13: file(relativePath: { eq: "artist13.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist14: file(relativePath: { eq: "artist14.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist16: file(relativePath: { eq: "artist16.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist17: file(relativePath: { eq: "artist17.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artist18: file(relativePath: { eq: "artist18.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    artistReel: file(relativePath: { eq: "artist-reel.png" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
